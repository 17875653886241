import { apiClient as client } from '@/shared/service/api-client';

export default {
    async findAllStages(params) {
        return await client.get('/customerStage/findAllStages', {
            params
        });
    },
    async createStage(params) {
        return await client.post('/customerStage/createStage', params);
    },
    async updateStage(params) {
        return await client.post('/customerStage/updateStage', params);
    },
    async deleteStage(params) {
        return await client.post('/customerStage/deleteStage', params);
    },
    async sort(params) {
        return await client.post('/customerStage/sort', params);
    },

}