<template>
  <div class='customer-group-detail'>
    <router-header :routerHeaderInfo="routerHeaderInfo" />
    <div class="detail_container">
      <div class="posting-detail">
        <a-row>
          <a-col class="detail_label"
                 :span="2">创建者:</a-col>
          <a-col class="detail_value">
            <div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="postingDetail.createdUserWorkWechatThirdAppUserId"></div>
          </a-col>
        </a-row>

        <a-row v-for="content in postingDetail.contents"
               :key="content.id">
          <template v-if="content.type === msgTypeEnum.TEXT">
            <a-col class="detail_label"
                   :span="2">群发消息1:</a-col>
            <a-col class="detail_value">
              {{ content.content }}
            </a-col>
          </template>
          <template v-if="content.type === msgTypeEnum.IMG">
            <a-col class="detail_label"
                   :span="2">群发消息2:</a-col>
            <a-col class="detail_value">
              <img class="message_image"
                   :src="content.cover"
                   alt="群发附件图">
            </a-col>
          </template>
          <template v-if="content.type === msgTypeEnum.LINK">
            <a-col class="detail_label"
                   :span="2">群发消息2:</a-col>
            <a-col class="detail_value">
              <div class="msg-link">
                <div class="link-text"
                     @click="goLinkUrl(content)">
                  <p class="title">{{ content.title }}</p>
                  <p class="abstract">{{ content.summary }}</p>
                </div>
                <img class="link-img"
                     :src="content.cover"
                     alt="群发附件链接图">
              </div>
            </a-col>
          </template>
        </a-row>

        <a-row>
          <a-col class="detail_label"
                 :span="2">群发类型:</a-col>
          <a-col class="detail_value">{{ postingDetail.sendType === sendTypeEnum.SEND_NOW ? "立即发送" : "定时发送" }}</a-col>
        </a-row>

        <a-row>
          <a-col class="detail_label"
                 :span="2">发送时间:</a-col>
          <a-col class="detail_value">{{ $f.datetime(postingDetail.sendTime, 'YYYY-MM-DD HH:mm') }}</a-col>
        </a-row>

        <a-row>
          <a-col class="detail_label"
                 :span="2">发送对象:</a-col>
          <a-col class="detail_value sendTo">
            <div class="sendTo_container is-flex">
              <span class="sendTo_label">群发员工</span>
              <div class="sendTo_account">
                <div class="account_container"
                     v-for="(staff,index) in sendObject.staffs"
                     :key="index">
                  <avatar-column :avatar="{avatar: staff.avatar, userId: staff.corpStaffWorkWechatThirdAppUserId }"
                                 :size="32"
                                 round
                                 is-user-id />
                </div>
              </div>
            </div>

            <div v-if="stageList.length"
                 class="sendTo_container is-flex">
              <span class="sendTo_label">客户阶段</span>
              <a-row>
                <a-col v-for="(stage, index) in stageList"
                       :key="index">
                  <a-tag>{{stage}}</a-tag>
                </a-col>
              </a-row>

            </div>
            <div v-if="corpTagGroups.length"
                 class="sendTo_container sendTo_corpTag is-flex">
              <span class="sendTo_label">企业标签</span>
              <div>
                <span class="sendTo_subLabel subTitle"
                      v-if="sendObject.tagFilterType === 'and'">(以下标签同时满足)</span><span class="sendTo_subLabel subTitle"
                      v-if="sendObject.tagFilterType === 'or'">(以下标签满足其中之一)</span>
                <div class="tag_container is-flex"
                     v-for="tagGroup in sendObject.corpTagGroups"
                     :key="tagGroup.workWechatTagGroupName">
                  <div class="sendTo_subLabel tag-group">{{tagGroup.workWechatTagGroupName}}</div>
                  <div class="tag_value">
                    <a-tag v-for="tag in tagGroup.corpTags"
                           :key="tag.workWechatTagId">
                      {{tag.name}}
                    </a-tag>
                  </div>
                </div>
              </div>

            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col class="detail_label"
                 :span="2">发送详情：</a-col>
          <a-col class="detail_value">
            <a-table :columns="columns"
                     :data-source="staffList"
                     :scroll="{  y: 600 }"
                     bordered
                     :pagination="false"
                     row-key="corpStaffId">
              <template #corpStaffWorkWechatThirdAppUserId="{ text }">
                <div v-is="'ww-open-data'"
                     class="ww-open-data ml-12"
                     style="max-width: 160px;"
                     type="userName"
                     :openid="text"></div>
              </template>

              <template #sendStatus="{ record }">
                {{ record.sendStatus ===  sendStatusEnum.UNSEND ? "未发送" : "已发送" }}
              </template>
            </a-table>
          </a-col>
        </a-row>
      </div>
      <div class="detail_display">
        <phone-box>
          <li v-for="msg in postingDetail.contents"
              :key="msg.id"
              class="reply-list__item">
            <svg-icon class="icon-default-avatar"
                      type="iconmorentouxiang"></svg-icon>
            <template v-if="msg.type === msgTypeEnum.TEXT">
              <div class="msg-text">
                {{msg.content}}
              </div>
            </template>
            <template v-if="msg.type === msgTypeEnum.IMG">
              <img class="msg-img"
                   :src="msg.cover"
                   alt="群发附件图片">
            </template>
            <template v-if="msg.type === msgTypeEnum.LINK">
              <msg-link class="cursor-default"
                        :msg="{title: msg.title, subTitle: msg.summary, img: msg.cover}">
              </msg-link>
            </template>
          </li>
        </phone-box>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
// import { Avatar } from "ant-design-vue";

import RouterHeader from "@/components/RouterHeader.vue";
import PhoneBox from "@/components/PhoneBox.vue";
import SvgIcon from "@/components/SvgIcon";
import MsgLink from "@/components/MsgLink";

import groupSendApi from "@/service/api/groupSend";
import customerStageApi from "@/service/api/CustomerStateApi";
import { getWxAgentConfig } from "@/global";
import AvatarColumn from "@/components/AvatarColumn";
export default {
  name: "CustomerGroupDetail",
  components: {
    // AAvatar: Avatar,

    RouterHeader,
    PhoneBox,
    SvgIcon,
    MsgLink,
    AvatarColumn,
  },

  setup() {
    const sendTypeEnum = {
      SEND_NOW: 0,
      SEND_TIMED: 1,
    };

    const sendStatusEnum = {
      UNSEND: 0,
      SEND: 1,
    };

    const msgTypeEnum = {
      TEXT: 0,
      IMG: 1,
      LINK: 2,
    };

    return {
      sendTypeEnum,
      sendStatusEnum,
      msgTypeEnum,
    };
  },
  data() {
    const routerHeaderInfo = [
      {
        path: "/customer/customerGroup",
        name: "客户群发",
      },
      { name: "群发详情" },
    ];

    const postingDetail = {};
    const sendObject = {};
    const columns = [
      {
        title: "员工",
        dataIndex: "corpStaffWorkWechatThirdAppUserId",
        align: "center",
        slots: { customRender: "corpStaffWorkWechatThirdAppUserId" },
      },
      {
        title: "状态",
        dataIndex: "sendStatus",
        align: "center",
        slots: { customRender: "sendStatus" },
      },
      {
        title: "待发送客户数",
        dataIndex: "prepareCustomerNum",
        align: "center",
      },
      {
        title: "发送成功数",
        dataIndex: "successNum",
        align: "center",
      },
      {
        title: "发送失败数",
        dataIndex: "failedNum",
        align: "center",
      },
    ];
    const staffList = [];
    const stageList = [];
    const corpTagGroups = [];

    getWxAgentConfig();
    return {
      routerHeaderInfo,
      postingDetail,
      sendObject,
      corpTagGroups,
      columns,
      staffList,
      stageList,
      sendCustomerMessageId: "",
    };
  },
  computed: {},
  mounted() {
    this.sendCustomerMessageId = this.$route.query.id;

    this.getPostingDeatil();
    this.getSendObject();
    this.getStaffList();
  },

  methods: {
    async getPostingDeatil() {
      const { sendCustomerMessageId } = this;
      this.postingDetail = await groupSendApi.getBase({
        sendCustomerMessageId,
      });
    },
    async getSendObject() {
      const { sendCustomerMessageId } = this;

      this.sendObject = await groupSendApi.getCustomerFilter({
        sendCustomerMessageId,
      });

      this.corpTagGroups = this.sendObject.corpTagGroups;
      const stages = _.split(this.sendObject.stages, ",");
      this.stageList = [];
      const allStages = await customerStageApi.findAllStages({
        withNoneStage: true,
      });

      _.forEach(allStages, (stage) => {
        if (_.includes(stages, stage.code)) {
          this.stageList.push(stage.name);
        }
      });
    },
    async getStaffList() {
      const { sendCustomerMessageId } = this;

      this.staffList = await groupSendApi.getStaffList({
        sendCustomerMessageId,
      });
    },

    goLinkUrl(content) {
      window.open(content.link);
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const length = vm.routerHeaderInfo.length;
      const path = vm.routerHeaderInfo[length - 2].path;
      if (!_.includes(["/", path], from.path)) {
        const {
          path,
          meta: { name },
          query,
        } = from;

        _.assign(vm.routerHeaderInfo[length - 2], {
          path,
          name,
          query,
        });
      }
    });
  },
};
</script>